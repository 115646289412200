<div *ngIf="searchRequired" class="table-head-wrp d-flex justify-content-between mt-4 mb-3 align-items-center" >
    <h6>All <span>({{ totalCount }})</span></h6>
    <div class="search-filterwrp d-flex">
        <div class="srch-bx ">
            <input [(ngModel)]="searchText" (input)="onSearch()" class="form-control" placeholder="Search" type="text">
            <img src="assets/icons/search.svg" alt="">
        </div>
    </div>
</div>
<div class="card">
    <div class="responsive-table th-sticky">
        <div class="">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear [matSortActive]="defaultSortColumn" [matSortDirection]="defaultSortOrder" (matSortChange)="onSortChange($event)">
        
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="toggleAllRows($event)" [checked]="isAllSelected()" [indeterminate]="someSelected()"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleRow(row)" [checked]="selection.isSelected(row)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of columns">
                    <ng-container matColumnDef="{{column.columnDef}}">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}</th>
                        <td mat-cell *matCellDef="let row">
                            <div [ngSwitch]="column.type">
                                <a *ngSwitchCase="'link'" class="{{ column.class }}" (click)="onCellClick(row,'view')">{{ column.cell(row) }}</a>                    
                                <div *ngSwitchCase="'text'" [innerHTML]="column.cell(row)!=='NaN/NaN/NaN'?column.cell(row):'Null'"></div>
                                <div *ngSwitchCase="'image'"><img class="{{ column.class }}" src="{{ column.cell(row) }}"></div>
                                <div *ngSwitchCase="'clickableText'" [innerHTML]="column.cell(row)!=='NaN/NaN/NaN'?column.cell(row):'Null'" (click)="onCellClick(row,'view')"></div>
								<div *ngSwitchCase="'RouteText'" [innerHTML]="column.cell(row)!=='NaN/NaN/NaN'?column.cell(row):'Null'" (click)="onCellClick(row,'route')"></div>
                                <mat-slide-toggle class="custom-toggle" *ngSwitchCase="'toggle'" [(ngModel)]="row[column.columnDef]" (change)="onPeriodToggle(row, column.columnDef)">{{ column.cell(row) }}</mat-slide-toggle>
                                <div *ngSwitchCase="'freqtoggle'">
                                    <span>Daily</span>
                                    <mat-slide-toggle class="custom-toggle dual-state" [ngModel]="isWeeklyEnabled(row)" (change)="onFrequencyToggle(row, column.columnDef)"></mat-slide-toggle>
                                    <span>Weekly</span>
                                </div>
                            </div>                                                
                        </td>
                    </ng-container>
                </ng-container>
                <ng-container matColumnDef="save" *ngIf="needSaving">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <img src="assets/icons/icon-tag-saved.svg" alt="" (click)="onCellClick(row,'removeSaved')" class="savedClass">
                    </td>
                </ng-container>
                <ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <div>
                                <button mat-button [matMenuTriggerFor]="menu" (click)="getActions(row)"><span><img src="assets/icons/icon-more.svg" alt=""></span></button>
                                <mat-menu  #menu="matMenu" yPosition="below">
                                    <button mat-menu-item *ngFor="let button of buttons" (click)="onCellClick(row, button.action)">{{ button.actionLabel }}</button>
                                </mat-menu> 
                            </div>
                        </td>
                    </ng-container>
                </ng-container>
        
                <!-- Disclaimer column - with nullable approach -->
                <ng-container matColumnDef="disclaimer" *ngIf="footer">
                    <td mat-footer-cell *matFooterCellDef colspan="100%">
                        <strong>{{footer}}</strong>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                <ng-container *ngIf="footer"> <!-- Make footer nullable -->
                    <tr mat-footer-row *matFooterRowDef="['disclaimer']" class="example-second-footer-row"></tr>
                </ng-container>
            </table>
        
            <mat-paginator *ngIf="dataSource && paginationRequired"
                class="" 
                [length]="totalCount"
                [pageSizeOptions]="pagination" 
                [pageSize]="pageSize"
                (page)="onPaginationChange($event)">
            </mat-paginator>
        </div>
    </div>
</div>
