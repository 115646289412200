import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/appconstants';
import { TableBtn, TableColumn } from 'src/app/interfaces';
import { TableProperties } from 'src/app/model/table/table-properties';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrl: './mat-table.component.css'
})
export class MatTableComponent implements OnChanges, AfterViewInit {
  @Input() columns: TableColumn[] = [];
  @Input() searchRequired: boolean = true;
  @Input() paginationRequired: boolean = false;
  @Input() actionsColumn: boolean = false;
  @Input() totalCount: any;
  @Input() buttons: TableBtn[] = [];
  @Input() data: any[] = [];
  @Input() filter: boolean = false;
  @Input() filterPlaceholder: string = 'Filter';
  @Input() footer?: string;
  @Input() pagination: number[] = [];
  @Input() pageSize!: number;
  @Input() defaultSortColumn: any;
  @Input() defaultSortOrder: any;
  @Input() tableMinWidth: number = 500;
  @Input() loading: boolean = false;
  @Output() tableChange = new EventEmitter<TableProperties>();
  @Output() actionClick = new EventEmitter<any>();
  @Output() cellClick = new EventEmitter<any>();
  @Output() toggleClick = new EventEmitter<any>();
  @Input() showCheckbox: boolean = false;
  @Input() needSaving: boolean = false;
  @Output() selectedApplicantsChange = new EventEmitter<any[]>(); // Emit selected applicants

  dataSource!: MatTableDataSource<any>;
  displayedColumns!: string[];
  tableProperties: TableProperties = new TableProperties();
  searchText = "";
  selection = new SelectionModel<any>(true, []); // Initialize SelectionModel for row selection

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      if (changes['data']) {
        this.dataSource = new MatTableDataSource(this.data);
        this.displayedColumns = [...this.columns.filter(c => !c.hide).map(c => c.columnDef)];
        if (this.needSaving) this.displayedColumns = [...this.displayedColumns, 'save'];
        if (this.actionsColumn) this.displayedColumns = [...this.displayedColumns, 'actions'];
        if (this.showCheckbox) this.displayedColumns = ['select', ...this.displayedColumns];

        //Reapply sorting data accessor
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'name':
              return `${item.firstName} ${item.lastName}`.toLowerCase();
            default:
              return item[property];
          }
        };

        this.dataSource.sort = this.sort;
        
      }
    }
    this.selection.clear()
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        console.log('Sorting:', item, property);
        switch (property) {
          case 'name':
            return `${item.firstName} ${item.lastName}`.toLowerCase();
          default:
            return item[property];
        }
      };
      this.sort.sortChange.subscribe(() => {
        if (this.paginator) {
          this.paginator.pageIndex = AppConstants.DEFAULTPAGINDEX;
        }
      });
    }
  }
  
  onSortChange(event: any) {
    this.tableProperties.sortOrder = event.direction;
    this.tableProperties.sortColumn = event.active;
    this.tableProperties.pageIndex = AppConstants.DEFAULTPAGINDEX;
    this.tableProperties.pageSize = AppConstants.DEFAULTPAGESIZE;
    this.tableChange.emit(this.tableProperties);
  }

  onPaginationChange(event: any) {
    this.tableProperties.sortOrder = this.sort.direction;
    this.tableProperties.sortColumn = this.sort.active;
    this.tableProperties.pageIndex = event.pageIndex ?? AppConstants.DEFAULTPAGINDEX;
    this.tableProperties.pageSize = event.pageSize ?? AppConstants.DEFAULTPAGESIZE;
    this.tableChange.emit(this.tableProperties);
  }

  onCellClick(row: any, action: any) {
    this.cellClick.emit({ row: row, action: action });
  }

  getActions(event: any) {
    this.actionClick.emit({ action: event });
  }

  onPeriodToggle(element: any, column: any) {
    this.toggleClick.emit({ row: element, action: column });
  }
  getIsEnabled(row: any, frequencyText: string): boolean {
    const frequency = row.alertFrequencies.find((freq: any) => freq.frequencyText === frequencyText);
    return frequency ? frequency.isEnabled : false;
  }

  onFrequencyToggle(row: any, column: any): void {
    const isWeeklyRemoved = this.isWeeklyEnabled(row);
    const frequency = row.alertFrequencies.find((freq: any) => freq.frequencyText === 'Weekly');
    if (frequency) {
      frequency.isEnabled = !isWeeklyRemoved; 
    }
    this.toggleClick.emit({ row: row, action: column, isWeeklyRemoved: isWeeklyRemoved });
  }
  isWeeklyEnabled(row: any): boolean {
    return row.alertFrequencies.some((freq: any) => freq.frequencyText === 'Weekly' && freq.isEnabled);
  }
  onSearch() {
    setTimeout(() => {
      this.tableProperties.sortOrder = this.sort.direction;
      this.tableProperties.sortColumn = this.sort.active;
      this.tableProperties.pageIndex = AppConstants.DEFAULTPAGINDEX;
      this.tableProperties.pageSize = AppConstants.DEFAULTPAGESIZE;
      this.tableProperties.searchText = this.searchText;
      this.tableChange.emit(this.tableProperties);
    }, 2000);
  }
  // Check if all rows are selected
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Check if some but not all rows are selected
  someSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected > 0 && numSelected < numRows;
  }

  updateSelectedApplicants(): void {
    const selectedApplicants = this.selection.selected;
    this.selectedApplicantsChange.emit(selectedApplicants);
  }

  // Toggle the selection of all rows
  toggleAllRows(event: any) {
    if (event.checked) {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    } else {
      this.selection.clear();
    }
    this.updateSelectedApplicants();
  }

  // Toggle the selection of a specific row
  toggleRow(row: any) {
    this.selection.toggle(row);
    this.updateSelectedApplicants();
  }
}
