import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableComponent } from './mat-table/mat-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { PhoneMaskDirective } from '../directives/phone-number/phone-number.directive';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EinVerifyDirective } from '../directives/EinVerifyDirective/EinVerify.directive';
import { SafeBlankDirective } from '../directives/prevent-reverse-tabnabbing.directive';
@NgModule({
  declarations: [
    MatTableComponent,
    PhoneMaskDirective,
    EinVerifyDirective,
    SafeBlankDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatMenuModule,
    MatCheckboxModule,
    MatCheckbox,
    MatProgressSpinner,
    MatSlideToggleModule
  ],
  exports: [
    MatTableComponent,
    PhoneMaskDirective,
    EinVerifyDirective,
    SafeBlankDirective
  ]
})
export class SharedModule { }
